// @see node_modules\@flightscope\baseball-stats\src\enums\QualityOfContact.js
const QualityOfContact = {
    // https://www.mlb.com/glossary/statcast/barrel
    Barrel: { key: 'Barrel', name: 'Barrell', description: 'Barrels are best possible batted balls. Generally they have a 95+ MPH Exit Velocity and are in the air, whether it’s a line drive or a fly ball.' },
    SolidContact: { key: 'SolidContact', name: 'Solid Contact', description: 'One notch below Barrels, but on average half as valuable. They basically form a border around barrels.' },
    FlareBurner: { key: 'FlareBurner', name: 'Flare', description: 'The Flares and Burners is that odd combination of trading plenty of speed for loft, or vice versa, so the ball drops in/between fielders.' },
    PoorlyUnder: { key: 'PoorlyUnder', name: 'Pop-Up', description: ' These are medium velocity fly balls (between flares and solid contact) and pop-ups (Exit Angle of 50+ degrees). These are the worst performing batted balls.' },
    PoorlyTopped: { key: 'PoorlyTopped', name: 'Dribbler', description: 'These are grounders at low-angles that don’t qualify as burners. The second worst performing batted ball.' },
    PoorlyWeak: { key: 'PoorlyWeak', name: 'Poorly/Weak', description: 'This is any batted ball under 60 MPH, and includes bunts. These actually perform at an above-average rate.' },
    Unidentified: { key: 'Unidentified', name: 'Unclassified', description: 'Unidentified.' },
};

export default QualityOfContact;
